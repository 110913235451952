html {
    --height-slider-arrow-s: 1.5625rem;
    --height-slider-arrow-m: 2rem;
    --height-slider-arrow-l: 3rem;
    --width-slider-arrow-s: 1.5625rem;
    --width-slider-arrow-m: 2rem;
    --width-slider-arrow-l: 3rem ;
    --default-slider-bg-color: var(--color-black);
    --default-slider-border-radius: 50%;
    --default-slider-svg-color: var(--color-white);
}
html{
    --size-sm:1.25rem;
    --size-xl:1.75rem;
}
html {
  --product-card-width: 15rem;
  --product-card-height-s: 11rem;
  --product-card-height-m: 24rem;
  --product-card-spacing-xs: 2px;
  --product-card-spacing-s: 6px;
  --product-card-spacing-m: 10px;
  --product-card-svg-height-width: 3rem;
}

html {
  --default-slideShow-arrow-size: 2.5rem;
  --default-slideShow-arrow-mobile-size: 2rem;
  --default-slideShow-arrow-bg-color: var(--color-black);
  --default-slideShow-arrow-color: var(--color-white);
  --default-slideShow-dot-size: 0.625rem;
  --default-slideShow-active-dot-width: 0.9375rem;
  --default-slideShow-height: 30rem;
  --default-slideShow-mobile-height: 15rem;
}

html{
    --data-card-increment:var(--color-green-900);
    --data-card-decrement:var(--color-red-700);
    --data-card-increment-light:var( --color-green-50);
    --data-card-decrement-light:var(--color-red-50);
    --size-font-l:12px;
    --font-weight-m:400
}
html {
  /**
 * * Design Tokens
 *  * These are the  Tokens need to used across the project so that changing a abosolute value here will refelect in entire components.
 */

  --size-xs: 0.25rem;
  --size-s: 0.5rem;
  --size-m: 1rem;
  --size-l: 1.5rem;
  --size-xl: 2rem;

  /* Spacing */
  --size-spacing-xxs: 0.25rem; /* 4px  */
  --size-spacing-xs: 0.5rem; /* 8px  */
  --size-spacing-s: 0.75rem; /* 12px  */
  --size-spacing-m: 1rem; /* 16px  */
  --size-spacing-l: 1.25rem; /* 20px  */
  --size-spacing-xl: 1.5rem; /* 24px  */
  --size-spacing-xxl: 1.75rem; /* 28px  */
  --size-spacing-xxxl: 2rem; /* 32px  */
  --size-spacing-h: 2.5rem; /* 40px  */

  /* Border Width */
  --size-border-width-s: 0.063rem; /* 1px  */
  --size-border-width-m: 0.125rem; /* 2px  */
  --size-border-width-l: 0.188rem; /* 3px  */

  /* Border Radius */
  --size-border-radius-tiny: 0.063rem; /* 1px */
  --size-border-radius-xxs: 0.125rem; /* 2px */
  --size-border-radius-xs: 0.25rem; /* 4px */
  --size-border-radius-s: 0.375rem; /* 6px */
  --size-border-radius-m: 0.5rem; /* 8px */
  --size-border-radius-l: 0.625rem; /* 10px */
  --size-border-radius-xl: 0.75rem; /* 12px */
  --size-border-radius-xxl: 0.875rem; /* 14px */
  --size-border-radius-h: 1rem; /* 16px */

  /* Text sizes */
  --size-font-xs: 0.625rem; /* 10px */
  --size-font-s: 0.75rem; /* 12px */
  --size-font-m: 0.875rem; /* 14px */
  --size-font-l: 1rem; /* 16px */

  /* Heading sizes */
  --size-heading-xxxl: 6rem; /* 96px */
  --size-heading-xxl: 3.75rem; /* 60px */
  --size-heading-xl: 3rem; /* 48px */
  --size-heading-l: 2.25rem; /* 36px */
  --size-heading-m: 1.5rem; /* 24px */
  --size-heading-s: 1.25rem; /* 20px */

  /* Font Weights */
  --font-weight-xxs: 100;
  --font-weight-xs: 200;
  --font-weight-s: 400;
  --font-weight-m: 500;
  --font-weight-l: 600;
  --font-weight-xl: 700;

  /* Box Shadows */
  --shadow-xs: 0rem 0.063rem 0.125rem rgba(34, 34, 34, 0.15); /* 0px 1px 2px */
  --shadow-s: 0rem 0.25rem 0.375rem rgba(34, 34, 34, 0.12); /*  0px 4px 6px */
  --shadow-m: 0rem 0.375rem 0.75rem rgba(34, 34, 34, 0.12); /* 0px 6px 12px */
  --shadow-l: 0rem 0.5rem 1rem rgba(34, 34, 34, 0.12); /* 0px 8px 16px */
  --shadow-xl: 0rem 1rem 2rem rgba(34, 34, 34, 0.12); /* 0px 16px 32px */

  /* Colours */
  /* Gray Colour Shades */
  --color-gray-50: #fcfcfc;
  --color-gray-100: #fafafa;
  --color-gray-200: #f4f4f5;
  --color-gray-300: #efeff1;
  --color-gray-400: #c9c9cf;
  --color-gray-500: #9e9ea9;
  --color-gray-600: #656571;
  --color-gray-700: #4d4d56;
  --color-gray-800: #323239;
  --color-gray-900: #1a1a1d;

  /* Purple Colour Shades */
  --color-purple-50: #eeeeff;
  --color-purple-100: #e0e7ff;
  --color-purple-200: #c7c8fe;
  --color-purple-300: #a5a7fc;
  --color-purple-400: #8184f8;
  --color-purple-500: #6366f1;
  --color-purple-600: #4649e5;
  --color-purple-700: #3032a3;
  --color-purple-800: #2e3081;
  --color-purple-900: #1b1c4b;

  /* Red Colour Shades */
  --color-red-50: #fff0f2;
  --color-red-100: #ffe3e6;
  --color-red-200: #ffcbd4;
  --color-red-300: #ffa1b0;
  --color-red-400: #ff6c85;
  --color-red-500: #fa1f4b;
  --color-red-600: #e8164a;
  --color-red-700: #c40c3e;
  --color-red-800: #a40d3b;
  --color-red-900: #8c0f39;

  /* Yellow Colour Shades */
  --color-yellow-50: #fff9ee;
  --color-yellow-100: #fff1db;
  --color-yellow-200: #ffdca0;
  --color-yellow-300: #ffcd78;
  --color-yellow-400: #ffbf51;
  --color-yellow-500: #ffb02a;
  --color-yellow-600: #ffa916;
  --color-yellow-700: #ffa103;
  --color-yellow-800: #ee9600;
  --color-yellow-900: #d08200;

  /* Green Colour Shades */
  --color-green-50: #f0fced;
  --color-green-100: #e2fadc;
  --color-green-200: #a8ef98;
  --color-green-300: #8cea76;
  --color-green-400: #6fe554;
  --color-green-500: #52e032;
  --color-green-600: #3fcc1f;
  --color-green-700: #39bb1c;
  --color-green-800: #2f9917;
  --color-green-900: #247712;

  /* Persian Green Colour Shades */
  --color-persian-green-50: #effefb;
  --color-persian-green-100: #c7fff4;
  --color-persian-green-200: #90ffea;
  --color-persian-green-300: #50f8df;
  --color-persian-green-400: #1ce5cd;
  --color-persian-green-500: #03b7a4;
  --color-persian-green-600: #00a294;
  --color-persian-green-700: #058078;
  --color-persian-green-800: #096660;
  --color-persian-green-900: #0d544f;

  /* Functional Tokens */
  --color-primary-breadcrumb: var(--color-gray-500);
  /*Default Colours */
  --color-primary: var(--color-purple-500);
  --color-success: var(--color-green-500);
  --color-warning: var(--color-yellow-500);
  --color-error: var(--color-red-500);
  --color-dark: var(--color-gray-900);
  --color-default: var(--color-gray-100);
  --color-disabled: var(--color-gray-300);

  --color-secondary: #00b6a4;
  --color-tertiary: #f97316;
  --color-black: #000000;
  --color-white: #ffffff;

  /* Text Colours */
  --color-text-primary: var(--color-gray-900);
  --color-text-secondary: var(--color-gray-800);
  --color-text-placeholder: var(--color-gray-500);

  /* Border Colours */
  --color-border-dark: var(--color-gray-900);
  --color-border-gray-500: var(--color-gray-500);
  --color-border-gray-400: var(--color-gray-400);
  --color-border-gray-300: var(--color-gray-300);
  --color-border-light: var(--color-gray-200);

  --color-border-primary: var(--color-primary);
  --color-border-success: var(--color-success);
  --color-border-warning: var(--color-warning);
  --color-border-error: var(--color-error);

  /* Active State Background Colours */
  --color-active-bg-primary: var(--color-purple-50);
  --color-active-bg-success: var(--color-green-50);
  --color-active-bg-warning: var(--color-yellow-50);
  --color-active-bg-error: var(--color-red-50);
  --color-active-bg-dark: var(--color-gray-50);

  --color-accent-text: var(--color-primary);

  /* Pagenics ImageWithText font-size */
  --content-description-font-size: 0.875rem;
  --content-subtitle-font-size: 1rem;
  --content-title-font-size: 1.875rem;
  --content-title-font-weight: 700;
}

body {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol,
    'Noto Color Emoji';
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

a {
  text-decoration: none;
}

img,
video {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

html {
  --size-circularProg-s: 3.5rem;
  --size-circularProg-m: 4.5rem;
  --size-circularProg-l: 5.5rem;
}

html {
  --color-skeleton: #e8e8e8;
  --bg-gradient: linear-gradient(
    to right,
    #f0f0f0 8%,
    #e0e0e0 18%,
    #f0f0f0 33%
  );
}

html {
  --size-close-xm: 0.563rem;
  --size-close-xl: 3rem;
  --size-close-xxl: 4.5rem;
  --size-close-xxxl: 14rem;
}

html {
  --size-file-margin-xs: 0.1rem;
  --size-file-margin-l: 2.87rem;
  --size-file-margin-m: 0.18rem;
  --size-file-xl: 3.62rem;
  --size-file-xxl: 7.5rem;
  --size-file-max-width: 21.25rem;
}

html {
  --color-light: #fff;
  --color-dark: #000;
  --color-primary-light: var(--color-purple-100);
  --color-primary-dark: var(--color-purple-700);
  --color-error-light: var(--color-red-100);
  --color-error-dark: var(--color-red-600);
  --color-warning-light: var(--color-yellow-100);
  --color-warning-dark: var(--color-yellow-800);
  --color-success-light: var(--color-green-100);
  --color-success-dark: var(--color-green-700);
  --color-tertiary-light: #fff5ee;
  --color-tertiary-dark: #ff5f15;
  --color-secondary-light: var(--color-green-100);
  --color-secondary-dark: var(--color-green-700);
  --size-font-xl: 1.5rem;
  --size-border-radius-xxxl: 1.5rem;
}

html{
    --size-width-s: 4rem;
}
html {
  --size-avatar-m: 2rem;
  --size-avatar-l: 2.5rem;
  --size-avatar-xl: 3rem;
  --size-avatar-xxl: 3.5rem;
  --size-avatar-xxxl: 4rem;
  --size-avatar-huge: 4.5rem;
}

html {
    --height-sticky: 3.8rem;
    --width-alert-message-s: 8rem;
    --width-alert-message-m: 10rem;
    --width-alert-message-l: 15rem;
    --width-alert-message-xl: 20rem;
    --width-alert-message-xxl: 25rem;
}
html {
  /* --size-switch-button-s: 0.5rem;
  --size-switch-button-m: 1rem;
  --size-switch-button-l: 2rem;
  --size-switch-button-xl: 3rem;

  --size-switch-lable-s: 0.75rem;
  --size-switch-lable-m: 0.875rem;
  --size-switch-lable-l: 2rem;
  --size-switch-lable-xl: 3rem; */

  --color-switch-primary: var(--color-primary);
  --color-switch-secondary: #ffffff;
}

html {
  /** Rating sizes **/
  --size-rating-s: 2rem;
  --size-rating-m: 2.5rem;
  --size-rating-l: 3rem;
  --size-rating-xl: 3.5rem;
 
  --color-rating-primary: #fdcc0d;
  --color-rating-secondary: #ff9529;
}

html {
  --color-dashboardCard-border: #cacaca;
  --dashboardCard-min-height: 13rem;
}

html {
  --tooltip-background-color: var(--color-black);
}

html {
    --tab-box-shadow-nil:  0 0 0 0 ;
    --tab-size-border-radius-nil: 0;
    --tab-size-border-width-nil: 0;
    --tab-color-background-nil: transparent;
    --tab-color-default-text: black;
}
html {
  --color-visualization-label: var(--color-black);
  --color-visualization-shade-50: var(--color-purple-50);
  --color-visualization-shade-100: var(--color-purple-100);
  --color-visualization-shade-200: var(--color-purple-200);
  --color-visualization-shade-300: var(--color-purple-300);
  --color-visualization-shade-400: var(--color-purple-400);
  --color-visualization-shade-500: var(--color-purple-500);
  --color-visualization-shade-600: var(--color-purple-600);
  --color-visualization-shade-700: var(--color-purple-700);
  --color-visualization-shade-800: var(--color-purple-800);
  --color-visualization-shade-900: var(--color-purple-900);
}

html {
    --color-visualization-label: var(--color-black);
    --color-visualization-shade-50: var(--color-purple-50);
    --color-visualization-shade-100: var(--color-purple-100);
    --color-visualization-shade-200: var(--color-purple-200);
    --color-visualization-shade-300: var(--color-purple-300);
    --color-visualization-shade-400: var(--color-purple-400);
    --color-visualization-shade-500: var(--color-purple-500);
    --color-visualization-shade-600: var(--color-purple-600);
    --color-visualization-shade-700: var(--color-purple-700);
    --color-visualization-shade-800: var(--color-purple-800);
    --color-visualization-shade-900: var(--color-purple-900);
  }

html {
   --color-visualization-shade-900:var(--color-purple-900);
   --color-visualization-shade-800:var(--color-purple-800);
   --color-visualization-shade-600:var(--color-purple-600);
   --color-visualization-shade-400:var(--color-purple-400);
  }
html {
    --postition-cros-icon: -0.125rem /* -2px */;
    --modal-background-color: #0006;
    --modal-width: 80%;
    --modal-height: 80%;
}
html {
  --color-border-input: var(--color-border-gray-300);
  --color-bg-input: var(--color-gray-100);
}

html {
  --background-color-accordion: #f1f1f1;
}
html {
  --filter-width-s: 6rem;
  --filter-width-m: 13rem;
  --filter-width-l: 15.625rem;
}

html {
  --border-radius-product-card: 6.25rem;
  --font-size-product-card-s: 0.8rem;
  --size-product-card-s: 1.9rem;
  --size-product-card-m: 1.95rem;
  --size-product-card-l: 3rem;
  --spacing-product-card-s: 0.2rem;
  --box-shadow-product-card: 0 2px 4px rgba(0, 0, 0, 0.1);
}

html{
    --drawer-bg-color: var(--color-white);
    --drawer-border: 1px solid transparent;
    --drawer-box-shadow:  1px 0px 7px rgba(0, 0, 0, 0.5);
}
html {
  --color-table-row-background: #f2f2f2;
  --color-table-row-border: #ddd;
}

html {
  --grid-spacing-xs: 0rem;
  --grid-spacing-s: 2rem;
  --grid-spacing-m: 4rem;
  --grid-spacing-l: 6rem;
}

html {
  --price-card-font: 1.75rem;
}

html {
  --product-collection-grid: 4;
}

html {
  /* variables required for feature navigator */
  --feature-navigator-spacing-xs: var(--size-spacing-m); /* 1rem */
  --feature-navigator-spacing-s: var(--size-spacing-xl); /* 1.5rem */
  --feature-navigator-spacing-m: 2rem;
  --feature-navigator-spacing-l: 3rem;
  --feature-navigator-font-xs: var(--size-font-l); /* 1rem */
  --feature-navigator-font-s: 1.25rem;
  --feature-navigator-font-m: 2rem;
  --feature-navigator-description-color: #656571;
  --feature-navigator-cross-icon-color: #4d4d56;
  --feature-navigator-hr-color: #656571;
}

html {
  --color-google-signIn-background: white;
}

html {
    --size-masonry-title-l: 1.8rem;
    --size-masonry-title-m: 1.2rem;
    --size-masonry-description-l: 1.2rem;
    --size-masonry-description-m: 0.875rem;
    --size-masonry-grid-gap-l: 2.1rem;
    --size-masonry-grid-gap-m: 1rem;
    --size-spacing-h: 2.5rem;
    --size-spacing-s: 0.7rem;
    --lineClamp-l: 20;
    --lineClamp-m: 6;
  }
html {
  --faq-color-background: #fafafa;
  --faq-dropdown-background: #fafafa;
  --faq-question-font-size: 1.1rem;
  --faq-question-font-size-bold: 1.3rem;
  --faq-sub-question-font-size: 1rem;
  --faq-answer-font-size: 0.9rem;
  --faq-open-answer-background: var(--color-white);
  --faq-dropdown-color: var(--color-black);
}

@media screen and (max-width: 425px) {
  html {
    --faq-question-font-size: 0.87rem;
    --faq-question-font-size-bold: 1rem;
    --faq-sub-question-font-size: 0.8rem;
    --faq-answer-font-size: 0.8rem;
  }
}
